import axios from "axios";
import axiosRetry from "axios-retry";

interface ApiServiceInterface {
    get: (routeName: string, params?: Record<string, any>,  retries?: number | Record<string, any>) => Promise<any>
    post: (routeName: string, data: Record<string, any>, retries?: number | Record<string, any>) => Promise<any>
    postUpload: (routeName: string, data: Record<string, any>) => Promise<any>
    put: (routeName: string, data: {
        id: number | string,
        data: Record<string, any>
    }) => Promise<any>
    delete: (routeName: string, id: number | string) => Promise<any>
    objectToFormData: (obj: { [key: string]: any },
                       form?: FormData,
                       namespace?: string) => FormData
}
const client = axios.create();
axiosRetry(client, { retries: 0 });

const ApiService: ApiServiceInterface = {
    get(routeName: string, params?: Record<string, any>, retries?: number | Record<string, any>) {
        const config = {params};
        if (retries) {
            config['axios-retry'] = retries
        }
        return client.get(route(routeName), {params})
    },
    post(routeName: string , data: Record<string, any>, retries?: number | Record<string, any>) {
        return client.post(route(routeName), data, retries)
    },
    postUpload(routeName, data) {
        return client.post(route(routeName), this.objectToFormData(data))
    },
    put(routeName, {id, data}) {
        return client.put(route(routeName, {id}), data)
    },
    delete(routeName, id) {
        return client.delete(route(routeName, {id}))
    },

    objectToFormData(
        obj: { [key: string]: any },
        form?: FormData,
        namespace?: string
    ): FormData {
        const fd: FormData = form || new FormData();
        let formKey: string;
        for (const property in obj) {
            if (namespace) {
                formKey = namespace + "[" + property + "]";
            } else {
                formKey = property;
            }
            if (
                typeof obj[property] === "object" &&
                !(obj[property] instanceof File)
            ) {
                this.objectToFormData(obj[property], fd, property);
            } else {
                if (obj[property] !== undefined) {
                    fd.append(formKey, obj[property]);
                }
            }
        }

        return fd;
    }
}

export default ApiService
